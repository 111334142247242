import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import './LoadingIndicator.styles.css';

const LoadingIndicator = () => (
  <Dimmer active inverted><Loader inverted className="sterling-loader" /></Dimmer>
);

export default LoadingIndicator;
