import React from 'react';
import { Container, Header, Message } from 'semantic-ui-react';

const Home = () => (
  <Container>
    <Message>
      <Header as="h3" content="Welcome" />
      <p>
        The
        {' '}
        <em>SCB Bulk Upload.</em>
      </p>
    </Message>
  </Container>
);

export default Home;
