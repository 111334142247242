import React, { Suspense, useState } from 'react';
import {
  Container,
  Header,
  Image,
} from 'semantic-ui-react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import NavigationMenu from './components/NavigationMenu';
import LoadingIndicator from './components/LoadingIndicator';
import ErrorBoundary from './components/ErrorBoundary';
import PrivateRoute from './components/PrivateRoute';

import AuthContext from './context/auth';
import { getHeaderToken, logout, checkAuth } from './resources/auth';
import { privateRoutes, routes } from './routes';

import 'semantic-ui-css/semantic.min.css';
import './App.css';

const authService = { getHeaderToken, logout, checkAuth };

const App = () => {
  const [loggedIn, setLoggedIn] = useState(checkAuth());
  return (
    <Router>
      <Container style={{ marginTop: '3em' }}>
        <Header as="h1">
          <Image src="/logo.png" />
          Bulk Upload
        </Header>

        <Suspense fallback={<LoadingIndicator />}>
          <ErrorBoundary>
            <AuthContext.Provider value={{ authService, loggedIn, setLoggedIn }}>
              <NavigationMenu />
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={`route-${index + 1}`}
                    path={route.path}
                    component={route.component}
                  />
                ))}
                {privateRoutes.map((route, index) => (
                  <PrivateRoute
                    key={`private-route-${index + 1}`}
                    path={route.path}
                    component={route.component}
                  />
                ))}
              </Switch>
            </AuthContext.Provider>
          </ErrorBoundary>
        </Suspense>
      </Container>
    </Router>
  );
};

export default App;
