import React, { useContext } from 'react';
import AuthContext from '../../context/auth';

import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

const AuthenticationButton = () => {
  const { loggedIn } = useContext(AuthContext);
  return (loggedIn ? <LogoutButton /> : <LoginButton />);
};

export default AuthenticationButton;
