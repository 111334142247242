import React, { SyntheticEvent, useContext } from 'react';
import { Menu } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../context/auth';

const LogoutButton = () => {
  const history = useHistory();
  const { authService, setLoggedIn } = useContext(AuthContext);

  const handleLogout = (event: SyntheticEvent): void => {
    event.preventDefault();

    authService.logout();
    setLoggedIn(false);
    history.push('/login');
  };

  return (
    <Menu.Item position="right" onClick={handleLogout}>Logout</Menu.Item>
  );
};

export default LogoutButton;
