/* eslint-disable import/prefer-default-export */
import Cookies from 'js-cookie';

const b64Decode = (str: string) => Buffer.from(str, 'base64').toString();

interface AccessToken {
  access_token: string;
  created_at: number;
  expires_at: string;
  expires_in: number;
  token_type: 'Bearer';
  scope: 'public';
}

const getTokenFromCookie = (): AccessToken | undefined => {
  const raw = Cookies.get('accessToken');
  if (raw?.length) {
    const fullToken = <AccessToken>JSON.parse(b64Decode(raw));
    return fullToken;
  }
  return undefined;
};

const deleteTokenFromCookie = () => { Cookies.remove('accessToken'); };

const checkAuth = () => getTokenFromCookie() !== undefined;

const getHeaderToken = (): Record<string, string> => ({
  Authorization: `Bearer ${getTokenFromCookie()?.access_token}`,
});

export default { checkAuth, getHeaderToken, logout: deleteTokenFromCookie };
