/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react';
import { getHeaderToken, logout, checkAuth } from '../resources/auth';

export default createContext({
  authService: {
    getHeaderToken,
    logout,
    checkAuth,
  },
  loggedIn: false,
  setLoggedIn: (_val: boolean) => {},
});
