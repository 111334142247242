import React, { Component, ErrorInfo } from 'react';
import PropTypes from 'prop-types';
import { NetworkError } from 'rest-hooks';

interface NetworkErrorResponse {
  code: number;
  details: unknown[];
  message: string;
}

interface State {
  hasError: boolean;
  error?: NetworkError;
  errorInfo?: ErrorInfo;
  errorResponse?: NetworkErrorResponse;
}

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  };

  constructor(props: { children: Component[] | Component }) {
    super(props);

    this.state = { hasError: false, errorResponse: undefined, error: undefined };
  }

  static getDerivedStateFromError(error: NetworkError) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: NetworkError) {
    if (error.response) {
      error.response.json().then((response: NetworkErrorResponse) => {
        this.setState({
          errorResponse: response,
        });
      });
    }
  }

  render() {
    const { props: { children } } = this;
    const { hasError, errorResponse, error } = this.state as State;
    if (hasError) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <p style={{ fontWeight: 900 }}>
              {error && error.message}
              {errorResponse && (` - ${errorResponse.message}`)}
            </p>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
