import React from 'react';
import { Segment } from 'semantic-ui-react';

const LoginPageContainer = () => (
  <Segment basic>
    You need to login before accessing secured resources.
    {' '}
    <a href="/auth/login">Click here to login.</a>
  </Segment>
);

export default LoginPageContainer;
