import Home from './Home';
import LoginPageContainer from './containers/LoginPageContainer';
import MemberBulkUploadPageContainer from './containers/bulk-upload/MemberBulkUploadPageContainer';

type Route = {
  path: string;
  component: any;
};

const routes: Route[] = [
  {
    path: '/login',
    component: LoginPageContainer,
  },
];
const privateRoutes: Route[] = [
  {
    path: '/upload/members',
    component: MemberBulkUploadPageContainer,
  },
  {
    path: '/',
    component: Home,
  },
];

export { routes, privateRoutes };
