/* eslint-disable no-console */
import React, { useState } from 'react';
import { useFetcher } from 'rest-hooks';

import MemberUploadResource from '../../resources/member-upload';
import BulkUploadPage from '../../components/bulk-upload/BulkUploadPage';

const MemberBulkUploadPageContainer = () => {
  const [isLoading, setLoading] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [networkError, setNetworkError] = useState('');

  const uploadMember = useFetcher(MemberUploadResource.create());
  const onSubmit = (selectedFiles: File[]) => {
    if (!selectedFiles) {
      return;
    }

    // Clear out old network error and set as loading
    setNetworkError('');
    setLoading(true);
    setUploadCompleted(false);

    const currentFile = selectedFiles[0];
    uploadMember({}, currentFile)
      .then(() => {
        setLoading(false);
        setUploadCompleted(true);
      })
      .catch((error: any) => {
        if (error.response) {
          error.response.clone().json().then((response: { code: number, message: string }) => {
            setNetworkError(response.message);
          });
        }
        setLoading(false);
      });
  };

  return (
    <BulkUploadPage
      loading={isLoading}
      completed={uploadCompleted}
      error={networkError}
      onSubmit={onSubmit}
    />
  );
};

export default MemberBulkUploadPageContainer;
