import React from 'react';
import {
  Segment,
  Message,
} from 'semantic-ui-react';

import LoadingIndicator from '../LoadingIndicator';
import ErrorMessage from '../ErrorMessage';
import BulkUploadForm from './BulkUploadForm';

const BulkUploadPage = ({
  onSubmit,
  loading,
  completed,
  error,
}: {
  onSubmit(files: File[] | undefined): void,
  loading?: boolean,
  completed?: boolean,
  error?: string,
}) => (
  <Segment basic>
    { loading && (<LoadingIndicator />) }
    { completed && (<Message positive>The file was successfully uploaded.</Message>) }
    { error && (<ErrorMessage message={error} />) }

    <BulkUploadForm onSubmit={onSubmit} />
  </Segment>
);

BulkUploadPage.defaultProps = {
  loading: false,
  completed: false,
  error: null,
};

export default BulkUploadPage;
