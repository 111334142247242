import React from 'react';
import { Message } from 'semantic-ui-react';

export interface ErrorMessageInferface {
  message: string;
}

const ErrorMessage = ({ message }: ErrorMessageInferface) => (
  <Message negative>
    <p>{message}</p>
  </Message>
);

export default ErrorMessage;
