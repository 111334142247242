import React, { useContext } from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/auth';

import AuthenticationButton from './login/AuthenticationButton';

const NavigationMenu = () => {
  const { loggedIn } = useContext(AuthContext);
  const privateMenu = loggedIn ? <Menu.Item as={Link} to="/upload/members">Members</Menu.Item> : '';

  return (
    <Menu compact inverted color="yellow" size="large">
      <Menu.Item as={Link} to="/">Home</Menu.Item>
      { privateMenu }
      <AuthenticationButton />
    </Menu>
  );
};

export default NavigationMenu;
