/* eslint-disable react/jsx-props-no-spreading */

import React, { useContext } from 'react';
import {
  Redirect, Route, RouteProps, useLocation,
} from 'react-router';
import AuthContext from '../context/auth';

export default function PrivateRoute({ ...routeProps }: RouteProps) {
  const location = useLocation();
  const { loggedIn } = useContext(AuthContext);

  if (loggedIn) {
    return <Route {...routeProps} />;
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {
          redirectTo: location.pathname,
        },
      }}
    />
  );
}
