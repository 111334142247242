import { Resource } from '@rest-hooks/rest';
import { getHeaderToken } from './auth';
import config from '../config';

export default class UploadResource extends Resource {
  readonly data: string = '';

  pk() {
    return this.data?.toString();
  }

  static useFetchInit(init: RequestInit) {
    return {
      ...init,
      headers: getHeaderToken(),
    };
  }

  static create<T extends typeof Resource>(this: T) {
    const endpoint = super.create();
    return endpoint.extend({
      schema: this,
      fetch(params: any, file: File) {
        const formData = new FormData();
        formData.append('file', file);
        return endpoint.fetch(params, formData);
      },
    });
  }

  static urlRoot = `${config.FLAT_FILE_API_URL}/v1/upload`;
}
