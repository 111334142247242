const envSettings = window as any;

const FLAT_FILE_API_URL = (process.env.REACT_APP_FLAT_FILE_API_URL)
  ? process.env.REACT_APP_FLAT_FILE_API_URL : envSettings.FLAT_FILE_API_URL;

const config = {
  FLAT_FILE_API_URL: `${FLAT_FILE_API_URL}`,
};

export default config;
