import React, { ChangeEvent, useState } from 'react';
import {
  Form,
  Button,
} from 'semantic-ui-react';

const BulkUploadForm = ({ onSubmit }: { onSubmit(files: File[] | undefined): void }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>();

  const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as typeof e.target & {
      files: File[];
    };
    setSelectedFiles(target.files);
  };
  const onFileSubmit = () => {
    onSubmit(selectedFiles);
  };

  return (
    <Form encType="multipart/form-data" onSubmit={onFileSubmit}>
      <Form.Field>
        <input
          type="file"
          id="upload-file"
          data-testid="upload-file"
          name="upload-file"
          accept=".csv"
          onChange={onFileSelect}
        />

      </Form.Field>
      <Button primary type="submit" disabled={!selectedFiles}>Upload .csv</Button>
    </Form>
  );
};

export default BulkUploadForm;
